<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <h2>Test</h2>
    </div>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <v-text-field
          v-model="name"
          :rules="requiredRules"
          label="Name"
          required
      ></v-text-field>
      <div>
        <label class="v-label theme--light">Category</label>
        <v-select
                  :options="categories"
                  :reduce="item => item._id"
                  v-model="category"
                  :getOptionLabel="item => item.name"
        ></v-select>
      </div>
      <div>
        <label class="v-label theme--light">Questions</label>
        <v-select
                  :options="questions"
                  multiple
                  :reduce="item => item._id"
                  v-model="tQuestions"
                  :getOptionLabel="item => item.name"
        ></v-select>
      </div>
      <v-text-field
          v-model="promocode"
          label="Promocode"
          required
      ></v-text-field>
      <v-text-field
          type="number"
          v-model="question_count"
          :rules="requiredRules"
          label="Question count"
          required
      ></v-text-field>
      <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="created_date"
              label="Created date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="created_date"
            @input="menu2 = false"
            locale="ru"
        ></v-date-picker>
      </v-menu>
      <div>
        <label class="v-label theme--light">Color</label>
        <v-text-field v-model="color" hide-details class="ma-0 pa-0" solo>
          <template v-slot:append>
            <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <div :style="swatchStyle" v-on="on" />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="color" flat />
                </v-card-text>
              </v-card>
            </v-menu>
          </template>
        </v-text-field>
      </div>

      <div>
        <v-file-input
            show-size
            label="Image"
            truncate-length="30"
            @change="onFileChange"
        ></v-file-input>
        <img
            v-if="imagePreviewURL"
            :src="imagePreviewURL"
            alt=""
            style="max-width: 100%;width: 250px; object-fit: cover"
        />
      </div>
      <v-switch
          v-model="active"
          :label="`${active ? 'Active': 'Disable'}`"
      ></v-switch>
      <v-btn
          :disabled="!valid"
          :loading="isLoading"
          color="success"
          class="mr-4"
          @click="submit"
      >
        Submit
      </v-btn>
    </v-form>
  </div>
</template>


<script>
  import vSelect from 'vue-select'
  export default {
    name: 'Test',
    components: {
      vSelect
    },
    data() {
      return {
        isLoading: false,
        valid: true,
        name: '',
        category: '',
        categories: [],
        promocode: '',
        question_count: '',
        created_date: '',
        active: false,
        image: '',
        imagePreviewURL: '',
        color: '#1976D2FF',
        mask: '!#XXXXXXXX',
        questions: [],
        tQuestions: [],
        menu: false,
        menu2: false,
        requiredRules: [
          v => !!v || 'Field is required',
        ],
      }
    },
    mounted() {
      this.getCategories();
      this.getQuestions();
      this.getTest();
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          if (this.category) {
            this.isLoading = true;
            let url = '/tests'
            let id = this.$route.params.id && this.$route.params.id !== 'create' ? this.$route.params.id : null;
            if (id) {
              url += '/' + id;
            }
            let data = {
              name: this.name,
              category: this.category,
              question_count: this.question_count,
              color: this.color,
              active: this.active,
            }
            if (this.image) {
              data.image = this.image;
            }
            if (this.tQuestions) {
              data.questions = this.tQuestions;
            }
            if (this.created_date) {
              data.created_date = this.created_date;
            }
            if (this.promocode) {
              data.promocode = this.promocode;
            }
            this.$axios[id ? 'put' : 'post'](url, data).then((res) => {
              this.$root.notify({color: 'success', text: 'Success created'})

              this.$router.push("/tests")
            }).catch(err => {
              this.isLoading = false;
              this.$root.notify({color: 'error', text: err.error ? err.error : "Unknown Error!"})
            });
          } else {
            this.$root.notify({color: 'error', text: 'Category is required'})
          }
        } else {
          this.$root.notify({color: 'error', text: 'Fill all fields'})
        }
      },
      onFileChange(payload) {
        const file = payload;
        this.image = file;
        if (file) {
          this.imagePreviewURL = URL.createObjectURL(file);
          URL.revokeObjectURL(file);
          this.uploadFile(file)
        } else {
          this.imagePreviewURL =  null
        }
      },
      uploadFile(f) {
        let formData = new FormData();
        formData.append("files", f);

        return this.$axios.post('/' + this.$store.state.filesUrl, formData).then(res => {
          this.image = res.data[0]._id;
        }).catch(err => {
          this.$root.notify({color: 'error', text: `${f.name} upload failed`})
        })
      },
      getTest() {
        let id = this.$route.params.id;
        if (id && id !== 'create') {
          let url = '/tests/' + id;
          this.$axios.get(url).then(res => {
            this.name = res.data.name;
            this.category = res.data.category && res.data.category._id ? res.data.category._id : res.data.category;
            this.created_date = res.data.created_date;
            this.promocode = res.data.promocode;
            this.question_count = res.data.question_count;
            this.color = res.data.color;
            this.active = res.data.active;
            this.tQuestions = res.data.questions;
            if (res.data.image) {
              this.imagePreviewURL = this.$store.state.appUrl + res.data.image.url;
            }
          }).catch(e => {
            console.log(e)
          });
        }
      },
      getCategories() {
        let url = '/categories';

        this.$axios.get(url).then(res => {
          this.categories = res.data;
        }).catch(e => {
          console.log(e)
        })
      },
      getQuestions() {
        let url = '/questions';

        this.$axios.get(url).then(res => {
          this.questions = res.data;
        }).catch(e => {
          console.log(e)
        })
      },
    },
    watch: {

    },
    computed: {
      swatchStyle() {
        const { color, menu } = this
        return {
          backgroundColor: color,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      }
    }
  }

</script>

